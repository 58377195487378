import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import RouteViewComponent from '../layouts/RouterBypass.vue'
import { useUserStore } from '../stores/user-store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'brands' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'brands' },
    children: [
     // {
     //   name: 'dashboard',
     //   path: 'dashboard',
     //   component: () => import('../pages/admin/dashboard/Dashboard.vue'),
     //   meta: { requiresAuth: true },
     // },
      {
        name: 'brands',
        path: 'brands',
        component: () => import('../pages/brands/BrandsPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'companies',
        path: 'companies',
        component: () => import('../pages/companies/CompaniesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'company-localities',
        path: 'company-localities/:id',
        component: () => import('../pages/company-localities/CompanyLocalities.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'client-modules',
        path: 'client-modules/:id&:name',
        component: () => import('../pages/client-modules/ClientModulesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'modules',
        path: 'modules',
        component: () => import('../pages/modules/ModulesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'localities',
        path: 'localities',
        component: () => import('../pages/localities/LocalitiesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'locality_types',
        path: 'locality_types',
        component: () => import('../pages/locality_types/LocalityTypesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'sort-types',
        path: 'sort-types',
        component: () => import('../pages/sort-types/SortTypes.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'palletization_types',
        path: 'palletization_types',
        component: () => import('../pages/palletization_types/PalletizationTypesPage.vue'),
        meta: { requiresAuth: true },
      },
      //{
      //  name: 'settings',
      //  path: 'settings',
      //  component: () => import('../pages/settings/Settings.vue'),
      //  meta: { requiresAuth: true },
      //},
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        name: 'contacts',
        path: 'contacts',
        component: () => import('../pages/contacts/ContactsPage.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const isAuthenticated = !!localStorage.getItem('authToken'); 

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' }); 
  } else if (requiresAdmin) {
    const userStore = useUserStore();
    await userStore.fetchUserData(); 
    if (!userStore.isAdmin) {
      next({ name: '' }); 
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router