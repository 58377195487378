export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  requiresAdmin?: boolean
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'brands',
      displayName: 'menu.brands',
      meta: {
        icon: 'sell',
      },
    },
    {
      name: 'modules',
      displayName: 'menu.modules',
      meta: {
        icon: 'view_module',
      },
    },
    {
      name: 'companies',
      displayName: 'menu.companies',
      meta: {
        icon: 'work',
      },
    },
    {
      name: 'localities',
      displayName: 'localities.localities',
      meta: {
        icon: 'apartment',
      },
      children: [
        {
          name: 'localities',
          displayName: 'localities.localities',
        },
        {
          name: 'locality_types',
          displayName: 'locality_types.locality_types_CDs',
        },
        {
          name: 'sort-types',
          displayName: 'menu.sort_types',
        },
        {
          name: 'palletization_types',
          displayName: 'menu.palletization_types',
        },
      ],
    },
    {
      name: 'contacts',
      displayName: 'contacts.contacts',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'users',
      displayName: 'users.users',
      meta: {
        icon: 'group',
      },
      requiresAdmin: true,
    },
    {
      name: 'preferences',
      displayName: 'menu.preferences',
      meta: {
        icon: 'manage_accounts',
      },
    },
  ] as INavigationRoute[],
}